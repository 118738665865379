import { NavigationStart, Router, Event, NavigationEnd, NavigationCancel } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { PatientService } from "../../../services/patient.service";
import { DomSanitizer, } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DoctorService } from '../../../services/doctor.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service'
import { MessageService, } from 'primeng/api';
import { refreshService } from '../../../services/refersh.service';
import { filter } from 'rxjs/operators';

import moment from 'moment';
@Component({
  selector: 'app-prescriptionpreview',
  templateUrl: './prescriptionpreview.component.html',
  styleUrls: ['./prescriptionpreview.component.scss'],
  providers: [MessageService]
})

// @Pipe({
//   name: 'safe'
// })


export class PrescriptionpreviewComponent implements OnInit, OnDestroy {
  @ViewChild("confrim_modal") confrim_modal!:ElementRef;
  @ViewChild("addShareWhatsAppModal") addShareWhatsAppModal!:ElementRef;

  showLoading = false;
  src = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  //obj: any = {};
  presc_url: string = "";
  hideBackButton = false;
  url: string = "";
  isSaved = false
  patientId: string = "";
  closeResult: any;
  isEditableVisit = false;
  favApptName = "";
  appointmentBook: any = false
  prescpobj: any
  patientData: any;
  userId:any = null
  dropDownPharmacy: any;
  showPDF = true;
  newUrl: any;
  emailFlag: any
  appointmentDate: any
  selectedPharmacy: any = null;
  sharedPrescriptionWithPatient: boolean = false;

  appointmentId: any = null;
  rxUi:any=""

  hidePrescriptionFlag: number = 0;

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private sanitizer: DomSanitizer,
    private doctorService: DoctorService,
    public dialog: MatDialog,
    private authService: AuthService,
    private messageService: MessageService,
    private refreshservice: refreshService
  ) {


  }
  ngOnDestroy(): void {
    //  this.router.navigate([`/doctor/patients/prescriptions/preview`],{ queryParams: {prescrip_url: this.presc_url,patientId: this.patientId} });
    //this.open(this.confrim_modal,'confrim_modal');
    sessionStorage.setItem("currentPreviewPrescriptionObject", "{}")

  }

  ngOnInit(): void {
    this.appointmentBook = false
    this.userId = this.authService.getUser();
    this.getDoctorDetails();
    this.prescpobj = JSON.parse(sessionStorage.getItem("currentPreviewPrescriptionObject")|| '[]');
    console.log("testdata", this.prescpobj)
    var followUpTypes = ""
    var bookAppointment = (localStorage.getItem("selectApptfollowupType"))
    this.appointmentDate = localStorage.getItem("followupDate")
    console.log("book", this.appointmentDate)
    var pat:any = localStorage.getItem("patientData");
    this.patientData = JSON.parse(pat);
    this.rxUi= (localStorage.getItem('RxUi'))


    if (bookAppointment == "Appointment") {
      this.appointmentBook = true
    }
    else if (bookAppointment == "Visit") {
      this.appointmentBook = false
    }
    else {
      this.appointmentBook = false
    }
    this.activatedRoute.queryParams
    .pipe(
      filter((params: any) => params.prescrip_url) // Use filter inside pipe
    )
    .subscribe((params: any) => {
      console.log(params);
      this.hideBackButton = true;
      this.presc_url = params.prescrip_url + '#toolbar=1';
      this.appointmentId = params.appointmentId;
      this.patientId = params.patientId;
      console.log(params.prescrip_url, "URL for prescription **********");
    });


  }
  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject:any) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }


  pdfURL = () => {

    let newUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.presc_url);

    return newUrl

  }

  saveFavPer = () => {
    var favApptName = this.favApptName
  }

  closePDF() {
    this.showPDF = true;
  }


  openPDF() {
    this.showPDF = false;
    // Remove nextAppointmentDateTime from localStorage
  localStorage.removeItem("nextAppointmentData");

  }

  saveAndSharePrescription = () => {

    // this.isSaved = true

    this.hideBackButton = true
    if (sessionStorage.getItem("currentPreviewPrescriptionObject")) {
      let prescpobj: any = JSON.parse(sessionStorage.getItem("currentPreviewPrescriptionObject")|| '[]');
      // console.log("hhh",prescpobj)
      prescpobj.action = 'share';
      prescpobj.favApptName = this.favApptName
      prescpobj.savePrescriptionParams.medicine_list = [];
      this.patientService.printPrescriptionVersion2(prescpobj).subscribe((response:any) => {
        if (response) {
          this.showLoading = false;
          console.log('PRESCRIPTION PDF', response)
          this.hideBackButton = true
          this.presc_url = response.url;


          //this.router.navigate([`/doctor/patients/prescriptions/preview`],{ queryParams: {prescrip_url: this.presc_url,patientId: this.patientId} });
        } else {
          this.showLoading = false;
        }
      });
    }
    else {
      console.log("did not get priscp object from session storage");
    }
  }
  donePrescription() {

    this.router.navigate([`/doctor/homepage/`], {});

  }

  shareReciept() {

    localStorage.setItem('patientData', JSON.stringify(this.patientData));
    this.refreshservice.goToshareRecepit();
    // this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { shareReciept: true } });

  }


  openDialog(content:any) {

    this.dialog.open(content, { disableClose: true });
    const dialogRef = {
      backdropClass: 'backdropBackground'
    }


  }
  closeDialog() {
    this.dialog.closeAll();
    this.showPDF = true;
  }

  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe((response:any) => {
      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);
          if (services.favPharmaInfo) {
            this.dropDownPharmacy = services.favPharmaInfo;
          }
          else {
            this.dropDownPharmacy = [];
          }

          if (services.hidePrescription) {
            this.hidePrescriptionFlag = services.hidePrescription;
          }
        }
      }
    })
  }


  onDropdownSelectFollowUpTypes(key:any, value:any) {
    this.emailFlag = false
    this.selectedPharmacy = value;

  }


  sharePrescriptionWithPharmacyViaEmail() {
    this.emailFlag = true;
    var postData = { url: this.presc_url.replace("_nomarginals", ""), pharmacyEmail: this.selectedPharmacy.email, patient: this.patientData, };
    this.patientService.sharePrescriptionWithPharmacy(postData).subscribe((response:any) => {
      this.emailFlag = true;
      this.messageService.add({ severity: 'info', summary: 'Sent email to pharmacy successfully', detail: '', });
    })
  }


  sharePrescriptionWithPharmacyViaWhatsApp() {

    const url = this.presc_url.replace("_nomarginals", "");
    let encoded = encodeURI(url);
    let text = "Prescription for" + " " + this.patientData.User.first_name
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)

    var waNo = this.selectedPharmacy.phoneNumber

    // Opening URL

    window.open(
      "https://web.whatsapp.com/send?phone=" + waNo + "&text=" + encoded,


      //   `https://web.whatsapp.com/send?text=${text} ${encoded} `,
      //https://web.whatsapp.com/send?phone=+9198xxxxxxx&text=urlencodedtext

      // This is what makes it 
      // open in a new window.
      '_blank'
    );
  }


  shareWithOther() {

    const url = this.presc_url.replace("_nomarginals", "");
    let encoded = encodeURI(url);
    let text = "Prescription for" + " " + this.patientData.User.first_name
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)



    window.open(

      `https://web.whatsapp.com/send?text=${text} ${encoded} `,

      '_blank'
    );
  }

  shareWithPatientApp() {
    if (!this.sharedPrescriptionWithPatient) {
      this.sharedPrescriptionWithPatient = true;
      let reqVars = {
        id: this.appointmentId,
        updateParam: { hidePrescription: 0 }
      }
      this.showLoading = true
  
      this.doctorService.updateAppointmentByIdAndParams(reqVars).subscribe((response:any) => {
        if (response.success) {
          this.showLoading = false;
          this.messageService.add({ severity: 'info', summary: 'Shared prescription with Patient in Health App', detail: '', });
        }else{
          this.messageService.add({ severity: 'info', summary: 'Something went wrong', detail: '', });
        }
      });

      

    }
  }
  openReceipt(){
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId, navgationValue: "Receipts" } })
    // this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId, navgationValue: item } })
    
  }

  backToEdit() {
    if(this.rxUi=='NewRxUI'){
      this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId: this.patientId,backToEdit: true} });
    }
    else{
      this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientId, backToEdit: true  ,appointment_id: this.appointmentId} });
    }
    // this.router.navigate([`/doctor/patients/editprescription/view`], { queryParams: { patientId: this.patientId, edit: true } });
   // 
  
  }


  bookAppointment() {
    localStorage.setItem("appDate", this.appointmentDate)
    localStorage.setItem('patientData', JSON.stringify(this.prescpobj));
    this.router.navigate(['/doctor/homepage/'], { queryParams: { patientId: this.prescpobj.patientId, action: 'appointment' } });
  }


  open(content:any, type:any) {
    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
      this.isEditableVisit = false

    });
  }



}
